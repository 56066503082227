<template>
  <b-card class="p-2">
    <b-card-body class="card-body py-2 px-0">
      <h4 class="title">Error al intentar generar factura</h4>
      <hr>
      <div v-if="notification">
        <RowComponent :title="'ERROR:'" :content="notification.data.text" />
        <div>
          <p>
            {{notification.data.closure.keops_error.data}}
            <small>{{notification.data.closure.keops_error.mensaje}}</small>
          </p>
        </div>
        <h4 class="title">Información del cliente</h4>
        <hr>
        <RowComponent :title="'ID: '" :content="customer.id.toString()" />
        <RowComponent :title="'CUIT/DNI: '" :content="customer.dni" />
        <RowComponent :title="'NOMBRE: '" :content="customer.name" />
        <RowComponent :title="'APELLIDO: '" :content="customer.last_name" />
        <RowComponent :title="'EMAIL: '" :content="customer.email" />
        <RowComponent :title="'MARCA: '" :content="customer.brand" />

        <h4 class="title">Información del la factura</h4>
        <hr>
        <RowComponent :title="'ID:'" :content="balance.id" />
        <RowComponent :title="'CONDICIÓN IVA:'" :content="balance.vat_condition" />
        <RowComponent :title="'TIPO DE COMPROBANTE:'" :content="balance.voucher_type" />
        <RowComponent
          :title="'MONTO TOTAL DE OPERACIONES:'"
          :content="balance.cost_send"
          type="amount"
        />
        <RowComponent
          :title="'MONTO TOTAL DE COLECTA:'"
          :content="balance.cost_operation"
          type="amount"
        />
        <RowComponent
          :title="'TOTAL :'"
          :content="balance.cost_send + balance.cost_operation "
          type="amount"
        />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  name: "bill-notification",
  data() {
    return {
      notification: null,
      customer: null,
      balance: null
    };
  },
  methods: {},
  mounted() {
    UserService.getNotification(this.$route.params.id).then(response => {
      this.notification = response.data.data;
      this.customer = this.notification.data.closure.customer;
      this.balance = this.notification.data.closure;
    });
  }
};
</script>

<style lang="scss">
.history-view {
  height: 400px;
  overflow-y: auto;
}
</style>
